export default {
  ゲーム友達を募集するならgamee: '\\ ゲーム友達を募集するなら /',
  //Common
  戻る: '戻る',
  もっと見る: 'もっと見る',
  最終更新: '最終更新',
  年: '年',
  月: '月',
  日: '日',
  平日: '平日',
  土日: '土日',
  時開始: '時',
  時終了: '時',
  //タブ
  ホーム: 'ホーム',
  募集掲示板: '掲示板',
  グループ一覧: 'グループ一覧',
  性格診断: '性格診断',
  診断ツール: '診断ツール',
  ゲーム友達募集: 'ゲーム友達募集',
  自己紹介カード: '自己紹介カード',
  マイページ: 'マイページ',
  //ヘッダー
  アプリ版gameeはサクサク動くし通知も受け取れるよ:
    'アプリ版gameeはサクサク動くし、通知も受け取れるよ！',
  インストール: 'インストール',
  ひらく: 'ひらく',
  新規登録: '新規登録',
  ログイン: 'ログイン',
  ログアウト: 'ログアウト',
  お知らせ: 'お知らせ',
  /* フッター */
  利用規約: '利用規約',
  プライバシーポリシー: 'プライバシーポリシー',
  ご要望はこちらから: 'ご要望はこちらから',
  公式Twitter: '公式Twitter',
  テンプレ提供はこちらから: 'テンプレ提供はこちらから',
  ApexLegends自己紹介カード: 'ApexLegends 自己紹介カード',
  Apexモバイル自己紹介カード: 'Apexモバイル 自己紹介カード',
  VALORANT自己紹介カード: 'VALORANT 自己紹介カード',
  FORTNITE自己紹介カード: 'FORTNITE 自己紹介カード',
  DBD自己紹介カード: 'DBD 自己紹介カード',
  AmongUs自己紹介カード: 'AmongUs 自己紹介カード',
  モンハンライズ自己紹介カード: 'モンハンライズ 自己紹介カード',
  ポケモンユナイト自己紹介カード: 'ポケモンユナイト 自己紹介カード',
  フォールガイズ自己紹介カード: 'フォールガイズ 自己紹介カード',
  スプラトゥーン3自己紹介カード: 'スプラトゥーン3 自己紹介カード',
  原神自己紹介カード: '原神 自己紹介カード',
  Overwatch2自己紹介カード: 'オーバーウォッチ2 自己紹介カード',
  CoDMW2自己紹介カード: 'CoD:MW2 自己紹介カード',
  ガンエボ自己紹介カード: 'ガンエボ 自己紹介カード',
  ヒロアカUR自己紹介カード: 'ヒロアカUR 自己紹介カード',
  //アプリDLモーダル
  アプリはサクサク動くし通知も受け取れるよ:
    '\\ アプリはサクサク動くし通知も受け取れるよ /',
  アプリをダウンロード: 'アプリをダウンロード',
  今はしない: '今はしない',
  ゲーム友達を募集したりゲームに誘うための機能が盛りだくさん:
    'ゲーム友達を募集したり、ゲームに誘うための機能が盛りだくさん🥳🎈',
  //ホーム
  性格診断: 'ゲーマータイプ性格診断',
  性格診断を始める: '性格診断を始める',
  武器診断を始める: '武器診断をはじめる',
  オンラインゲームの自己紹介カードが作成編集できる:
    '① オンラインゲームの自己紹介カードが作成・編集できる',
  今遊べるゲーム友達を自動で募集できる:
    '② 今遊べるゲーム友達を自動で募集できる',
  ゲーム募集掲示板で友達が探せる: '③ ゲーム募集掲示板で友達が探せる',
  対応ゲーム近日追加予定: '※対応ゲーム近日追加予定',
  最近のゲーム募集: '最近のゲーム募集',
  最近マッチしたルーム: '最近マッチしたルーム',
  新着のユーザー: '新着のユーザー',
  新着の自己紹介カード: '新着の自己紹介カード',
  自己紹介カードテンプレート: '自己紹介カードテンプレート',
  サービスをご利用いただくにはSNSログインが必要です:
    'サービスをご利用いただくにはSNSログインが必要です。',
  さっそくゲーム募集をつくってみる: 'さっそくゲーム募集をつくってみる',
  さっそく自己紹介カードをつくる: 'さっそく自己紹介カードをつくる',
  ゲームを選んでください: 'ゲームタイトルを選んでください',
  自己紹介カードをつくるゲームを選んでください:
    '自己紹介カードをつくるゲームを選んでください',
  これでつくる: 'これでつくる',
  キャンセル: 'キャンセル',
  ピックアップグッズ: 'ピックアップグッズ',
  運営が選んだ本当にいいと思えるゲームグッズだけをピックアップしています:
    '運営が選んだ本当にいいと思えるゲームグッズだけをピックアップしています。',
  //マイページ
  プロフィールを編集: 'プロフィールを編集',
  遊んでいるゲーム: '遊んでいるゲーム',
  あなたの自己紹介カード: 'あなたの自己紹介カード',
  //ログイン
  Appleでサインイン: 'Appleでサインイン',
  Twitterでサインイン: 'Twitterでサインイン',
  Googleでサインイン: 'Googleでサインイン',
  //自己紹介カード詳細
  の自己紹介カード: 'の自己紹介カード',
  テンプレ制作: 'テンプレ制作',
  のTwitterをみる: 'のTwitterをみる',
  のプロフィールをみる: 'のプロフィールをみる',
  あなたの自己紹介カードをつくる: 'あなたの自己紹介カードをつくる',
  /* 自己紹介カード作成ツール */
  //Apex
  ApexLegendsかわいい自己紹介カード作成ツール:
    '【Apex】かわいい自己紹介カード作成ツール【シーズン23】',
  ApexLegendsかわいい自己紹介カード作成ツール説明文:
    'Apex(エーペックス)のかわいい自己紹介カードが簡単に作成・編集できるツールです！🥳🎉 ランクやプラットフォーム、好きなレジェンドなどを選択・記入して自分だけのオリジナルAPEX自己紹介カードが簡単に作成できます。プレビューを見ながら文字入れをしてあなただけの自己紹介カードをつくろう！',
  //MyHeroAcademia
  ヒロアカURかわいい自己紹介カード作成ツール:
    '【ヒロアカUR】かわいい自己紹介カード作成ツール【僕のヒーローアカデミア ULTRA RUMBLE】',
  ヒロアカURかわいい自己紹介カード作成ツール説明文:
    'ヒロアカURのかわいい自己紹介カードが簡単に作成・編集できるツールです！🥳🎉 プレビューを見ながら文字入れをしてあなただけの自己紹介カードをつくろう！',
  //BF2042
  BF2042かわいい自己紹介カード作成ツール:
    '【BF2042】かわいい自己紹介カード作成ツール',
  BF2042かわいい自己紹介カード作成ツール説明文:
    'BF2042のかわいい自己紹介カードが簡単に作成・編集できるツールです！🥳🎉 プレビューを見ながら文字入れをしてあなただけの自己紹介カードをつくろう！',
  //BlueProtocol
  BlueProtocolかわいい自己紹介カード作成ツール:
    '【BLUE PROTOCOL】かわいい自己紹介カード作成ツール【ブループロトコル】',
  BlueProtocolかわいい自己紹介カード作成ツール説明文:
    'BlueProtocol(ブループロトコル)のかわいい自己紹介カードが簡単に作成・編集できるツールです！🥳🎉 プレビューを見ながら文字入れをしてあなただけの自己紹介カードをつくろう！',
  //Apexモバイル
  Apexモバイルかわいい自己紹介カード作成ツール:
    '【Apexモバイル】かわいい自己紹介カード作成ツール【エペモバ】',
  Apexモバイルかわいい自己紹介カード作成ツール説明文:
    'Apexモバイル(エペモバ)のかわいい自己紹介カードが簡単に作成・編集できるツールです！🥳🎉 プレビューを見ながら文字入れをしてあなただけの自己紹介カードをつくろう！',
  //VALORANT
  VALORANTかわいい自己紹介カード作成ツール:
    '【VALORANT】かわいい自己紹介カード作成ツール【ヴァロラント】',
  VALORANTかわいい自己紹介カード作成ツール説明文:
    'VALORANT(ヴァロラント)のかわいい自己紹介カードが簡単に作成・編集できるツールです！🥳🎉 プレビューを見ながら文字入れをしてあなただけの自己紹介カードをつくろう！',
  //DbD
  DbDかわいい自己紹介カード作成ツール:
    '【DbD】かわいい自己紹介カード作成ツール【デッドバイデイライト】',
  DbDかわいい自己紹介カード作成ツール説明文:
    'DbD(デッドバイデイライト)のかわいい自己紹介カードが簡単に作成・編集できるツールです！🥳🎉 プレビューを見ながら文字入れをしてあなただけの自己紹介カードをつくろう！',
  //PokemonUnite
  PokemonUniteかわいい自己紹介カード作成ツール:
    '【ポケモンユナイト】かわいい自己紹介カード作成ツール【UNITE】',
  PokemonUniteかわいい自己紹介カード作成ツール説明文:
    'ポケモンユナイト(PokemonUnite)のかわいい自己紹介カードが簡単に作成・編集できるツールです！🥳🎉 プレビューを見ながら文字入れをしてあなただけの自己紹介カードをつくろう！',
  //Fortnite
  Fortniteかわいい自己紹介カード作成ツール:
    '【フォートナイト】かわいい自己紹介カード作成ツール【Fortnite】',
  Fortniteかわいい自己紹介カード作成ツール説明文:
    'フォートナイト(Fortnite)のかわいい自己紹介カードが簡単に作成・編集できるツールです！🥳🎉プレビューを見ながら文字入れをしてあなただけの自己紹介カードをつくろう！',
  //FallGuys
  FallGuysかわいい自己紹介カード作成ツール:
    '【Fall Guys(フォールガイズ)】かわいい自己紹介カード作成ツール',
  FallGuysかわいい自己紹介カード作成ツール説明文:
    'フォールガイズ(Fall Guys)のかわいい自己紹介カードが簡単に作成・編集できるツールです！🥳🎉プレビューを見ながら文字入れをしてあなただけの自己紹介カードをつくろう！',
  //Splatoon3
  Splatoon3かわいい自己紹介カード作成ツール:
    '【スプラ3】かわいい自己紹介カード作成ツール【スプラトゥーン3】',
  //原神
  原神かわいい自己紹介カード作成ツール:
    '【原神】かわいい自己紹介カード作成ツール',
  原神かわいい自己紹介カード作成ツール説明文:
    '原神自己紹介カード作成ツールです。原神(Genshin Impact)のかわいい自己紹介カードが簡単に作成・編集できるツールです！🥳🎉 プレビューを見ながら文字入れをしてあなただけの自己紹介カードをつくろう！',
  Splatoon3かわいい自己紹介カード作成ツール説明文:
    'スプラ自己紹介カード作成ツールです。スプラ3のかわいい自己紹介カードテンプレートで簡単に作成・編集できます！🥳🎉 ウデマエやバイトレート、好きなモードやブキなどを選択・記入して自分だけのオリジナルスプラ自己紹介カードが簡単に作成できます。プレビューを見ながら文字入れをしてあなただけの自己紹介カードをつくろう！',
  //Overwatch2
  Overwatch2かわいい自己紹介カード作成ツール:
    '【オーバーウォッチ2(OW2)】かわいい自己紹介カード作成ツール【シーズン2】',
  Overwatch2かわいい自己紹介カード作成ツール説明文:
    'Overwatch2(オーバーウォッチ2)のかわいい自己紹介カードが簡単に作成・編集できるツールです！🥳🎉 プレビューを見ながら文字入れをしてあなただけの自己紹介カードをつくろう！',
  //CoDMW2
  CoDMW2かわいい自己紹介カード作成ツール:
    '【CoDMW2】かわいい自己紹介カード作成ツール【CoDモダンウォーフェア2】',
  CoDMW2かわいい自己紹介カード作成ツール説明文:
    'CoDMW2(CoDモダンウォーフェア2)のかわいい自己紹介カードが簡単に作成・編集できるツールです！🥳🎉 プレビューを見ながら文字入れをしてあなただけの自己紹介カードをつくろう！',
  //CoDMW3
  CoDMW3かわいい自己紹介カード作成ツール:
    '【CoDMW3】かわいい自己紹介カード作成ツール【CoDモダンウォーフェア3】',
  CoDMW3かわいい自己紹介カード作成ツール説明文:
    'CoDMW3(CoDモダンウォーフェア3)のかわいい自己紹介カードが簡単に作成・編集できるツールです！🥳🎉 プレビューを見ながら文字入れをしてあなただけの自己紹介カードをつくろう！',
  //CoDBo6
  CoDBo6かわいい自己紹介カード作成ツール:
    '【CoDBo6】かわいい自己紹介カード作成ツール【CoDブラックオプス6】',
  CoDBo6かわいい自己紹介カード作成ツール説明文:
    'CoDBo6(CoDブラックオプス6)のかわいい自己紹介カードが簡単に作成・編集できるツールです！🥳🎉 プレビューを見ながら文字入れをしてあなただけの自己紹介カードをつくろう！',
  //パズドラ
  パズドラかわいい自己紹介カード作成ツール:
    '【パズドラ】かわいい自己紹介カード作成ツール',
  パズドラかわいい自己紹介カード作成ツール説明文:
    'パズドラのかわいい自己紹介カードが簡単に作成・編集できるツールです！🥳🎉 プレビューを見ながら文字入れをしてあなただけの自己紹介カードをつくろう！',
  //ガンエボ
  ガンエボかわいい自己紹介カード作成ツール:
    '【ガンエボ】かわいい自己紹介カード作成ツール【ガンダムエボリューション】',
  ガンエボかわいい自己紹介カード作成ツール説明文:
    'ガンダムエボリューション(ガンエボ)のかわいい自己紹介カードが簡単に作成・編集できるツールです！🥳🎉 プレビューを見ながら文字入れをしてあなただけの自己紹介カードをつくろう！',
  //AmongUs
  AmongUsかわいい自己紹介カード作成ツール:
    '【アマングアス】かわいい自己紹介カード作成ツール【Among Us】',
  AmongUsかわいい自己紹介カード作成ツール説明文:
    'アモアス(Among Us)のかわいい自己紹介カードが簡単に作成・編集できるツールです！🥳🎉 プレビューを見ながら文字入れをしてあなただけの自己紹介カードをつくろう！',

  //説明
  いろんな種類の中から好きなカードを選ぼう:
    '① いろんな種類の中から好きなカードを選ぼう',
  名前やIDよく使うキャラなどあなたのことがみんなにわかるように項目を埋めよう:
    '② 名前やID、よく使うキャラなどあなたのことがみんなにわかるように項目を埋めよう',
  完成したらXにシェアしてみよう友だちが見つかりやすくなるよ:
    '③ 完成したらX(旧Twitter)にシェアしてみよう！友だちが見つかりやすくなるよ',
  推奨環境: '推奨環境：Chrome, Safari',
  プライベートブラウザやアドブロッカーを有効にしているとカードの作成に失敗することがあります:
    '※プライベートブラウザやアドブロッカーを有効にしていると、カードの作成に失敗することがあります😢',
  テンプレートを選ぶ: 'テンプレートを選ぶ',
  カードの背景色を選ぶ: 'カードの背景色を選ぶ',
  文字色を選ぶ: '文字色を選ぶ',
  フォントを選ぶ: 'フォントを選ぶ',
  アイコン画像: 'アイコン画像',
  なまえ: 'なまえ',
  ID: 'ID',
  性別: '性別',
  プラットフォーム: 'プラットフォーム',
  招待スタイル: '招待スタイル',
  募集スタイル: '募集スタイル',
  プレイスタイル: 'プレイスタイル',
  ボイスチャット: 'ボイスチャット',
  ボイスチャットその他: 'ボイスチャット(その他)',
  クラフト: 'クラフト', //Fortnite
  プレイ時間帯平日: 'プレイ時間帯(平日)',
  プレイ時間帯土日: 'プレイ時間帯(土日)',
  プレイ時間帯自由記述: 'プレイ時間帯(自由記述)',
  から: 'から',
  好きな武器: '好きな武器',
  好きなマップ: '好きなマップ',
  ロール: 'ロール',
  好きなブキ: '好きなブキ',
  はじめた時期: 'はじめた時期',
  世界ランク: '世界ランク',
  活動日数: '活動日数',
  よく使うレジェンド: 'よく使うレジェンド',
  よく使うエージェント: 'よく使うエージェント',
  よく使うキャラ: 'よく使うキャラ',
  よく使うヒーロー: 'よく使うヒーロー',
  使用キラー: '使用キラー',
  使用サバイバー: '使用サバイバー',
  好きなクルーの色: '好きなクルーの色',
  KD: 'K/D',
  最高ダメージ: '最高ダメージ',
  最高ランク: '最高ランク',
  目標ランク: '目標ランク',
  クラン: 'クラン',
  使用機体: '使用機体',
  ゲームモード: 'ゲームモード',
  ランク: 'ランク',
  アリーナランク: 'アリーナランク',
  チーム: 'チーム', //Fortnite
  好き放題言っちゃおう: '好き放題言っちゃおうのコーナー',
  ひとこと: 'ひとこと',
  カードの作成にはSNS連携が必要です次の画面でログインをします:
    '\\ カードの作成にはSNS連携が必要です。次の画面でログインをします /',
  これでカードをつくる: 'これでつくる！',
  /* ランク */
  //APEX
  ルーキー: 'ルーキー',
  ブロンズ: 'ブロンズ',
  シルバー: 'シルバー',
  ゴールド: 'ゴールド',
  プラチナ: 'プラチナ',
  ダイヤ: 'ダイヤ',
  マスター: 'マスター',
  プレデター: 'プレデター',
  //VALORANT
  アイアン: 'アイアン',
  // ブロンズ: 'ブロンズ',
  // シルバー: 'シルバー',
  // ゴールド: 'ゴールド',
  // プラチナ: 'プラチナ',
  // ダイヤ: 'ダイヤ',
  アセンダント: 'アセンダント',
  イモータル: 'イモータル',
  レディアント: 'レディアント',
  //Fortnite
  できない: 'できない',
  少しできる: '少しできる',
  できる方: 'できる方',
  建築職人: '建築職人',
  //PokemonUnite
  ビギナー: 'ビギナー',
  スーパー: 'スーパー',
  ハイパー: 'ハイパー',
  エリート: 'エリート',
  エキスパート: 'エキスパート',
  // マスター: 'マスター', //Duplicate
  //Overwatch2
  // ブロンズ: 'ブロンズ',
  // シルバー: 'シルバー',
  // ゴールド: 'ゴールド',
  // プラチナ: 'プラチナ',
  // ダイヤモンド: 'ダイヤモンド',
  // マスター: 'マスター',
  グランドマスター: 'グランドマスター',
  TOP500: 'TOP500',
  オーナー: 'オーナー',
}
